import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      title: "David Hodgson",
      intro: "I'm a front-end designer and developer specializing in React and Vue ecosystems. With experience in SCSS, GSAP, and Storybook, I am equipped to create dynamic and responsive applications.",
      viewResume: "View Resume",
      contactMe: "Contact Me",
      contactHeader: "Contact Me",
      emailPlaceholder: "Your email",
      messagePlaceholder: "Your message",
      send: "Send",
      messageSent: "Message Sent Successfully!",
      messageFailed: "Failed to send message.",
      caseStudies: "Case Studies",
      viewSite: "View Site",
      moreInfo: "More Info",
      duration: "Duration:",
      weeks: " weeks",
      planningDesign: "Planning/Design",
      uiUx: "UI/UX Design",
      development: "Development"
    }
  },
  ja: {
    translation: {
      title: "ディビッド・ハドソン",
      intro: "ReactやVueとそのエコシステムを専門とするフロントエンドデザイナー兼開発者です。SCSS、GSAP、Storybookでの経験を活かし、ダイナミックでレスポンシブなアプリケーションを作成します。",
      viewResume: "履歴書を見る",
      contactMe: "お問い合わせ",
      contactHeader: "お問い合わせ",
      emailPlaceholder: "あなたのメール",
      messagePlaceholder: "あなたのメッセージ",
      send: "送信",
      messageSent: "送信に成功しました！",
      messageFailed: "メッセージの送信に失敗しました。",
      caseStudies: "ポートフォリオ",
      viewSite: "サイトを見る",
      moreInfo: "もっと見る",
      duration: "期間:",
      weeks: "週間",
      planningDesign: "企画 / デザイン",
      uiUx: "UI/UX設計",
      development: "開発"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
