import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { useTranslation } from 'react-i18next';
import "./App.css";
import avatar from './assets/avatar.png';
import './i18n';
import emailjs from 'emailjs-com';
import resume from './assets/Resume.pdf';
import introVideo from './assets/intro2.mp4';
import caseStudyImage from './assets/patchsign.jpg';
import caseStudyImage2 from './assets/alliq.png';
import caseStudyImage3 from './assets/linola.png';
import caseStudyImage4 from './assets/bridgebooks.png';
import caseStudyImage5 from './assets/uniqlo.png';
import caseStudyImage6 from './assets/vik.png';

// 20250303 Update

gsap.registerPlugin(MotionPathPlugin);

function App() {
  const { t, i18n } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [toast, setToast] = useState({ visible: false, message: '', type: 'success' });
  const [indicatorVisible, setIndicatorVisible] = useState(true);
  const particlesRef = useRef(null);

  const toggleLanguage = () => {
    const newLang = i18n.language === 'en' ? 'ja' : 'en';
    i18n.changeLanguage(newLang);
  };

  const languageToggleText = i18n.language === 'en' ? '日本語' : 'English';

  const handleSubmit = (event) => {
    event.preventDefault();
    emailjs.init("K4vjke5EFkI4Nl8yY");
    emailjs.sendForm('service_kdpnvkc', 'template_lm83xh5', event.target)
    .then((result) => {
      console.log('Email successfully sent!');
      setToast({ visible: true, message: t('messageSent'), type: 'success' });
      setTimeout(() => setToast({ visible: false, message: '', type: '' }), 3000);
      handleCloseModal();
      document.querySelector('input[name="email"]').value = '';
      document.querySelector('textarea[name="message"]').value = '';
    }, (error) => {
      console.log('Failed to send email:', error.text);
      setToast({ visible: true, message: t('messageFailed'), type: 'error' });
      setTimeout(() => setToast({ visible: false, message: '', type: '' }), 3000);
    });
  };

  const handleScroll = () => {
    const profileCard = document.querySelector('.profile-card');
    if (profileCard.scrollTop > 10) {
      setIndicatorVisible(false);
    } else {
      setIndicatorVisible(true);
    }
  };

  useEffect(() => {
    const colors = ["#f4ee42", "#e4f442", "#c7f442", "#a9f442", "#8cf442"];
    const particles = particlesRef.current;

    for (let i = 0; i < 75; i++) {
      let div = document.createElement("div");
      div.className = "particle";
      div.style.backgroundColor = colors[Math.floor(Math.random() * colors.length)];
      particles.appendChild(div);
      const animateParticle = () => {
        gsap.fromTo(
          div,
          {
            x: () => Math.random() * window.innerWidth,
            y: () => Math.random() * window.innerHeight,
            autoAlpha: 0,
            scale: 0.5,
          },
          {
            duration: () => Math.random() * 6 + 4,
            motionPath: {
              path: createRandomPath(),
              align: "self",
              alignOrigin: [0.5, 0.5],
              autoRotate: true,
            },
            scale: () => Math.random() * 0.75 + 0.25,
            autoAlpha: () => Math.random() * 0.5 + 0.5,
            onComplete: animateParticle,
            ease: "power1.inOut",
          }
        );
      };

      animateParticle();
    }

    function createRandomPath() {
      const path = [
        { x: Math.random() * window.innerWidth, y: Math.random() * window.innerHeight },
        { x: Math.random() * window.innerWidth, y: Math.random() * window.innerHeight },
        { x: Math.random() * window.innerWidth, y: Math.random() * window.innerHeight },
      ];
      return path;
    }
  }, []);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <div className="App">
      <button className="locale-select" onClick={toggleLanguage}>
        {languageToggleText}
      </button>
      <div className="particles" ref={particlesRef}></div>
      <div className="profile-card" onScroll={handleScroll}>
        <div className="profile-info">
        <img src={avatar} alt="Profile" className="profile-image" />
          <div className="profile-text">
            <h1 className={i18n.language === 'ja' ? 'japanese' : ''}>{t('title')}</h1>
            <p>{t('intro')}</p>
          </div>
        </div>
        <video className="intro-video" controls>
          <source src={introVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="button-group">
          <button className="download-button" onClick={() => window.open(resume, '_blank')}>
            {t('viewResume')}
          </button>
          <button className="cta-button" onClick={handleOpenModal}>{t('contactMe')}</button>
        </div>

        <div className="case-studies">
          <h2>{t('caseStudies')}</h2>
          {/* Case Study 1 */}
          <div className="case-study">
            <div className="case-study-text">
              <h3><strong>Patchsign</strong></h3>
              {/* <p>A short description of Project A. This project involved building a full-stack application.</p> */}
              <div className="case-study-tags">
                <span className="badge">{t('planningDesign')}</span>
                <span className="badge">{t('uiUx')}</span>
                <span className="badge">{t('development')}</span>
              </div>
              <p><span className="tech-used">Vue, Figma, GitHub</span></p>
              <p><strong>{t('duration')}</strong> 24{t('weeks')}</p>
              <button className="download-button" onClick={() => window.open('https://www.patchsign.com/', '_blank')}>
                {t('moreInfo')}
              </button>
            </div>
            <div className="case-study-image">
              <img src={caseStudyImage} alt="Project A" />
            </div>
          </div>

          {/* Case Study 2 */}
          <div className="case-study">
            <div className="case-study-text">
              <h3><strong>Alliq</strong></h3>
              {/* <p>This project was focused on building a mobile app with a React Native framework.</p> */}
              <div className="case-study-tags">
                <span className="badge">{t('uiUx')}</span>
                <span className="badge">{t('development')}</span>
              </div>
              <p><span className="tech-used">Wordpress, Figma, GitHub</span></p>
              <p><strong>{t('duration')}</strong> 12{t('weeks')}</p>
              <button className="download-button" onClick={() => window.open('https://alliq.co.jp/', '_blank')}>
                {t('viewSite')}
              </button>
            </div>
            <div className="case-study-image">
              <img src={caseStudyImage2} alt="Project B" />
            </div>
          </div>

          {/* Case Study 3 */}
          <div className="case-study">
            <div className="case-study-text">
              <h3><strong>Linola</strong></h3>
              {/* <p>This project was a collaboration tool with an integrated calendar and task management system.</p> */}
              <div className="case-study-tags">
                <span className="badge">{t('planningDesign')}</span>
                <span className="badge">{t('uiUx')}</span>
                <span className="badge">{t('development')}</span>
              </div>
              <p><span className="tech-used">Wordpress, Adobe XD</span></p>
              <p><strong>{t('duration')}</strong> 14{t('weeks')}</p>
              <button className="download-button" onClick={() => window.open('https://linola.org/', '_blank')}>
                {t('viewSite')}
              </button>
            </div>
            <div className="case-study-image">
              <img src={caseStudyImage3} alt="Project C" />
            </div>
          </div>
          {/* Case Study 4 */}
          <div className="case-study">
            <div className="case-study-text">
              <h3><strong>BridgeBooks</strong></h3>
              {/* <p>A short description of Project A. This project involved building a full-stack application.</p> */}
              <div className="case-study-tags">
                <span className="badge">{t('planningDesign')}</span>
                <span className="badge">{t('uiUx')}</span>
                <span className="badge">{t('development')}</span>
              </div>
              <p><span className="tech-used">React, MUI, Figma, BitBucket</span></p>
              <p><strong>{t('duration')}</strong> 26{t('weeks')}</p>
              <button className="download-button" onClick={() => window.open('https://www.bbs-grp.com/bridgbooks', '_blank')}>
                {t('moreInfo')}
              </button>
            </div>
            <div className="case-study-image">
              <img src={caseStudyImage4} alt="Project A" />
            </div>
          </div>

          {/* Case Study 5 */}
          <div className="case-study">
            <div className="case-study-text">
              <h3><strong>Uniqlo</strong></h3>
              {/* <p>This project was focused on building a mobile app with a React Native framework.</p> */}
              <div className="case-study-tags">
                <span className="badge">{t('uiUx')}</span>
                <span className="badge">{t('development')}</span>
              </div>
              <p><span className="tech-used">React, Sketch, GitHub</span></p>
              <p><strong>{t('duration')}</strong> 32{t('weeks')}</p>
              <button className="download-button" onClick={() => window.open('https://www.uniqlo.com/ca/en/', '_blank')}>
                {t('viewSite')}
              </button>
            </div>
            <div className="case-study-image">
              <img src={caseStudyImage5} alt="Project B" />
            </div>
          </div>

          {/* Case Study 6 */}
          <div className="case-study">
            <div className="case-study-text">
              <h3><strong>Value Innovation</strong></h3>
              {/* <p>This project was a collaboration tool with an integrated calendar and task management system.</p> */}
              <div className="case-study-tags">
                <span className="badge">{t('planningDesign')}</span>
                <span className="badge">{t('uiUx')}</span>
                <span className="badge">{t('development')}</span>
              </div>
              <p><span className="tech-used">Wordpress, Shopify</span></p>
              <p><strong>{t('duration')}</strong> 12{t('weeks')}</p>
              <button className="download-button" onClick={() => window.open('https://vik.jp/', '_blank')}>
                {t('viewSite')}
              </button>
            </div>
            <div className="case-study-image">
              <img src={caseStudyImage6} alt="Project C" />
            </div>
          </div>
        </div>

        {indicatorVisible && (
          <div className="scroll-indicator">↓</div>
        )}
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={handleCloseModal}>&times;</span>
            <h2>{t('contactHeader')}</h2>
            <form onSubmit={handleSubmit}>
              <input type="email" name="email" placeholder={t('emailPlaceholder')} required />
              <textarea name="message" placeholder={t('messagePlaceholder')} required></textarea>
              <button type="submit">{t('send')}</button>
            </form>
          </div>
        </div>
      )}
      {toast.visible && (
        <div className={`message-toast ${toast.type}`}>
          {toast.message}
        </div>
      )}
    </div>
  );
}

export default App;
